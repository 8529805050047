<template>
  <div class="row">
    <div class="col-md-11">
      <v-breadcrumbs :items="breadcrumbs" divider=">">
        <v-breadcrumbs-item
          @click.native="
            fetchFiles({ folder: true, name: item.text, id: item.id })
          "
          v-for="item in breadcrumbs"
          :key="item.text"
          style="cursor: pointer"
        >
          {{ item.text }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </div>
    <div class="col-md-1">
      <input
        type="file"
        @change="fileChanged"
        ref="file"
        multiple
        style="display: none"
      />
      <v-btn
        class="mx-2"
        small
        fab
        dark
        color="indigo"
        @click="$refs.file.click()"
      >
        <v-icon dark> mdi-plus </v-icon>
      </v-btn>
    </div>
    <div class="col-md-2" v-for="file in files" :key="file.id">
      <div class="text--primary text-right files-menu">
        <!-- <div class="col-md-12"> -->

        <v-menu style="float: right" hi="name" type="submit">
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark icon v-bind="attrs" v-on="on">
              <v-icon color="black">mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="!file.folder">
              <v-list-item-title style="cursor: pointer">
                <span @click.prevent="downloadFile(file)">Download File</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer">
              <v-list-item-title>
                <span @click.prevent="shareFile(file)">Share</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer">
              <v-list-item-title>
                <span @click.prevent="showRenameDialog(file)">Rename</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item style="cursor: pointer">
              <v-list-item-title>
                <span @click.prevent="deleteFile(file)">Delete</span>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- </div> -->
      </div>
      <draggable v-if="file.folder">
        <span
          @dragstart="dragstart($event, file)"
          @drop="drop($event, file)"
          class="material-icons"
          style="font-size: 100px; display: block; cursor: pointer"
          @click="fetchFiles(file)"
          >folder</span
        >
      </draggable>
      <draggable v-else>
        <div
          @dragstart="dragstart($event, file)"
          style="overflow: hidden; cursor: pointer"
          @click="downloadFile(file)"
        >
          <img
            v-if="file.thumbnail"
            :src="file.thumbnail"
            alt=""
            class="img img-responsive"
            style="max-height: 94px"
          />
          <v-icon v-else size="96"> mdi-file-document-outline </v-icon>
          <!-- </v-card> -->
        </div>
      </draggable>

      <span class="file-name" :title="file.name">{{ file.name }}</span>
      <p class="created-date">{{ formatDate(file.createdDateTime) }}</p>
    </div>

    <RenameFile @updateName="updateName" :file="f" />
  </div>
</template>
<script>
import util from "@/util/util";
import draggable from "vuedraggable";
import RenameFile from "./rename";
export default {
  components: { draggable, RenameFile },
  props: {
    files: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stack: [],
      loading: false,
      file: null,
      f: {},
    };
  },
  methods: {
    fileChanged(e) {
      console.log(e);
      let files = e.target.files;
      this.$emit(
        "filesUploaded",
        files,
        this.$store.state.stack[this.$store.state.stack.length - 1]
      );
    },
    dragstart($event, file) {
      console.log("set file");
      $event.dataTransfer.setData("file", JSON.stringify(file));
      // console.log($event);
      // console.log($event.dataTransfer.getData("file_id"));
    },
    drop($event, destination) {
      this.$emit(
        "move",
        JSON.parse($event.dataTransfer.getData("file")),
        destination
      );
    },
    shareFile(file) {
      this.$emit("shareFile", file);
    },
    showRenameDialog(file) {
      this.f = file;
      this.$store.commit("toggleRenameDialog", true);
    },
    updateName(file, name) {
      this.$emit("renameFile", file, name);
    },
    deleteFile(file) {
      this.$emit("deleteFile", file);
    },
    downloadFile(file) {
      this.$emit("downloadFile", file);
    },
    fetchFiles(file) {
      this.$emit("fetchFiles", file);
    },
    formatDate(date) {
      return util.changeDateFormat(date);
    },
  },
  computed: {
    breadcrumbs() {
      return this.$store.state.stack.map((group) => {
        return {
          text: group.name,
          disabled: false,
          id: group.id,
          link: true,
        };
      });
    },
  },
};
</script>
<style lang="scss">
.files-menu:hover {
  display: hidden;
}
</style>
